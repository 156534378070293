<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <AreasModal
      :modal.sync="modal"
      :areaId="areaId"
      :AreaItems="AreaItems"
      :edit="edit"
      :title="titleModal"
      @submited="handleSubmit"
      @close="closeModal"
    />
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end">
        <CButton
          color="add"
          class="d-flex align-items-center"
          v-c-tooltip="{
            content: ('AREA'),
            placement: 'top-end'
          }"
          @click="toggleAdd"
        >
        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedItems"
          :fields="fields"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="tableText.itemsPerPage"
          hover
          small
          sorter
          pagination
        >
        <template #SquareMeters="{ item }">
          <td class="text-center align-middle">
            {{ item.SquareMeters ? formatMilDecimal(item.SquareMeters?.toFixed(2)) : '0,00' }}
          </td>
        </template>
        <template #Status="{ item }">
          <td class="text-center align-middle">
            <CBadge :color="getBadge(item.Status)">
              {{$t('label.'+item.Status)}}
            </CBadge>
          </td>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center align-middle">
            <CButton
              color="edit"
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+('AREA'),
                placement: 'top-end'
              }"
              @click="toggleEdit(item)"
            >
            <CIcon name="pencil" />
            </CButton>
          </td>
         </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>
</template>
    
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import { mapState } from "vuex";
import AreasModal from "./areas-modal";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
     
//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    areaId: '',
    edit: false,
    AreaItems: {},
    titleModal:''
  };
}
    
//METHOD
function getListAreas() {
  this.Loading = true;
  this.$http
    .get('YardArea-list',{ YardId: this.getYardId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}
    
function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nueva')+' '+('AREA');
}
function toggleEdit(item) {
  this.areaId = item.AreaId;
  this.AreaItems =item;
  this.edit = true;
  this.modal = true;
  let title = this.AreaItems.YardAreaName;
  this.titleModal= this.$t('label.edit')+' '+('AREA')+': '+title
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.AreaItems = {};
  this.titleModal = '';
}
function resetDataTab(){
  this.items = [];
}
function handleSubmit() {
  this.getListAreas();
}
    
// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "YardAreaName", label:("Area"),_style: 'width:10%;',_classes:'text-center' },
    { key: "Width", label:this.$t("label.width")+' '+'(M)',_style: 'width:19%;',_classes:'text-center'},
    { key: "Length", label:this.$t("label.largo")+' '+'(M)',_style: 'width:19%;',_classes:'text-center' },
    { key: "SquareMeters", label: 'M²', _style: 'width:22%;',_classes:'text-center' },
    { key: "Usuario", label: this.$t("label.user"),_style: 'width:10%;',_classes:'text-center' },
    { key: "FormatedDate", label: this.$t("label.date"),_style: 'width:10%;', _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_style: 'width:10%;', _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width:45px;",
    },
  ];
}

function formatedItems() {
  return this.items.map((items) =>
    Object.assign({}, items, {
      Nro: Number.parseInt(items.Nro),
      YardAreaName: items.YardAreaName,
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      Status: items.Status,
      Width: items.Width ? formatMilDecimal(items.Width?.toFixed(2)) : '0,00',
      Length: items.Length ? formatMilDecimal(items.Length?.toFixed(2)) : '0,00',
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}   
export default {
  name: "index",
  mixins: [General],
  props: {},
  data,
  components: {
    AreasModal,
  },
  mounted() {
    //this.getListAreas();
  },
  methods: {
    getListAreas,
    toggleAdd,
    toggleEdit,
    closeModal,
    resetDataTab,
    handleSubmit,
    formatMilDecimal,
  },
  computed: {
    fields,
    formatedItems,
    getYardId,
    ...mapState({
      activeTab: state => state.yard.tabIndex,
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 4) {
        this.getListAreas();
        this.resetDataTab();
      }
    },
  },
};
</script>
<style scoped>
</style>