<template>
    <div>
      <CCollapse :show="!collapseBlocks">
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
        <BlocksBaysModal
          :modal.sync="modal"
          :BlockBaysItems="BlockBaysItems"
          :edit="edit"
          :title="titleModal"
          @submited="handleSubmit"
          @close="closeModal"
        />
        <CRow>
          <CCol sm="12" lg="10" class="mt-3 d-flex justify-content-start px-0">       
            <CSelect
              :label="$t('label.subclassification')"
              :horizontal="{label: 'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-6'}"
              :options="SudClassificationOptions"
              add-label-classes="text-left"
              class="col-sm-9"    
              v-model="YardSubclasificationId"
              @change="changeSubclasification($event)"    
            />
          </CCol>
          <CCol sm="12" lg="2" class="d-flex justify-content-end align-items-center">
              <CButton
                color="add"
                class="d-flex align-items-center"
                v-c-tooltip="{
                  content: $t('label.blocksBays'),
                  placement: 'top-end'
                }"
                @click="toggleAdd"
              >
              <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>        
              </CButton>
          </CCol>
        </CRow> 
        <CRow>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="formatedItems"
              :fields="fields"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            >
              <template #Status="{ item }">
                <td class="text-center align-middle">
                  <CBadge :color="getBadge(item.Status)">
                    {{$t('label.'+item.Status)}}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                  <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.blocksBays'),
                      placement: 'top-end'
                    }"
                    @click="toggleEdit(item)"
                  >
                  <CIcon name="pencil" />
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.see')+$t('label.details'),
                      placement: 'top-end'
                    }"
                  @click="viewDetails(item)"
                  >
                  <CIcon name="cil-list" />
                </CButton>
                <CButton
                    v-if = disabledReportBottom
                    color="danger"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.generateReport'),
                      placement: 'top-end'
                    }"
                  
                  >
                  <CIcon name="cil-file-pdf" />
                </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </CCollapse>
      <CCollapse :show="collapseBlocks">
        <div v-if="collapseBlocks">
          <MenuBlocks  :titleHeight="titleHeight" />
        </div>
      </CCollapse>
    </div>
</template>
    
<script>
import { mapState } from 'vuex';
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import BlocksBaysModal from "./blocksBays-modal";
import MenuBlocks from "./menu-blocks";      

//DATA
function data() {
  return {
    items: [],
    SudClassification: [],
    Loading:  false,
    modal: false,
    BlockBaysItems: {},
    edit: false,
    titleModal: '',
    YardSubclasificationId: '',
    titleHeight: '',
    disabledReportBottom: false,
  };
}
  
//METHOD

function changeSubclasification(event) {
  this.YardSubclasificationId = event.target.value;
  this.getBlockBaysList();
}


function getBlockBaysList() {
  this.Loading = true;
  this.$http
    .get('YardBlockList-by-SubclasificationId',{YardId: this.getYardId, YardSubclasificationId: this.YardSubclasificationId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
        this.getSubclasificationList();
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function getSubclasificationList() {
  this.Loading = true;
  this.$http
    .get("YardSubClasification-list-by-YardId", {YardId: this.getYardId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.SudClassification = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.block');
}

function toggleEdit(item) {
  this.BlockBaysItems = item;
  this.edit = true;
  this.modal = true;
  let title = this.BlockBaysItems.BlockAlias;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.block')+': '+title
}

function viewDetails(item) {
  this.titleHeight= item.BlockAlias;
  this.$store.state.yard.heightJson = item.HeightJson; 
  this.$store.state.yard.YardBlockId = item.YardBlockId;
  this.$store.state.yard.collapseBlocks = true;
}

function closeModal(){
  this.modal = false;
  this.edit = false;
  this.BlockBaysItems = {};
  this.titleModal = '';
}

function resetDataTab(){
  this.items = [];
  this.SudClassification = [];
  this.SudClassificationId = '';
  this.$store.state.yard.collapseHeightPlan = false;
  this.$store.state.yard.collapseBlocks = false;
}

function handleSubmit() {
  this.getBlockBaysList();
}
    
// COMPUTED
function fields() {
    return [
    {
        key: "Nro",
        label: "#",
        _style: "width:1%;",
        filter: false,
    },
    { key: "BlockAlias", label: this.$t("label.block"),_style: 'width:12%;',_classes:'text-center',},
    { key: "YardSubClasificationName", label: this.$t("label.subclassification"),_style: 'width:12%;',_classes:'text-center',},
    { key: "Height", label: this.$t("label.height_"),_style: 'width:10%;',_classes:'text-center',},
    { key: "StackQuantity",  label: this.$t("label.stack"),_style: 'width:10%;',_classes:'text-center',},
    { key: "BayQuantity", label: this.$t("label.bays"),_style: 'width:10%;',_classes:'text-center',},
    { key: "Usuario", label: this.$t("label.user"),_style: 'width:10%;',_classes:'text-center' },
    { key: "FormatedDate", label: this.$t("label.date"),_style: 'width:10%;', _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_style: 'width:10%;', _classes: "text-center" },
    {
        key: "Detalle",
        label: "",
        sorter: false,
        filter: false,
        _style: 'width:5%; min-width:135px'
    },
    ];
}
function formatedItems() {
    return this.items.map((items) =>
        Object.assign({}, items, {  
            Nro: items.Nro,
            Usuario: items.TransaLogin ?? "N/A",
            YardSubClasificationName: items.YardSubClasificationName ?? "N/A",
            FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
            _classes: items.Status === "INACTIVO" ? "table-danger" : "",
        })
    );
}
function SudClassificationOptions(){
  if(this.SudClassification.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.SudClassification.map(function(e){
      if (e.FgActYardSubClasi) {
        chart.push({
          value: e.YardSubClasificationId, 
          label: e.YardSubClasificationName,  
        })   
      }  
    })
    return chart;
  }
}
function getYardId() {
     return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
export default {
  name: "index",
  mixins: [General],
  props: {Tab: Number},
  data,
  components: {
    BlocksBaysModal,
    MenuBlocks,
  },
  methods: {
    changeSubclasification,
    getBlockBaysList,
    getSubclasificationList,
    toggleAdd,
    toggleEdit,
    viewDetails,
    handleSubmit,
    closeModal,
    resetDataTab,
  },
  computed: {
    fields,
    formatedItems,
    SudClassificationOptions,
    getYardId,
    ...mapState({
        activeTab: state => state.yard.tabIndex,
        yardSelected: state => state.yard.yardSelected,
        collapseBlocks: state => state.yard.collapseBlocks,
    }),
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 2) {
        this.getBlockBaysList();
        this.resetDataTab();
      }
    },
  },
};
</script>
